<template>
  <data-table
    :headers="headers"
    :items="items"
    :last-page="serverLastPage"
    :loading="loading"
    :options="pagination"
    :total="serverItemsLength"
    @update:sort-by="onSortBy"
    @update:sort-desc="onSortDesc"
    @update:options="onPagination"
    @update:page="onPage"
  >
    <template #item="{ item }">
      <offer-row
        :key="`pricelist-prd-${item.id}`"
        :price-list="priceList"
        :price-list-id="priceList.id"
        :product="item"
      />
    </template>
  </data-table>
</template>

<script lang="ts">
import type { PriceList } from "@planetadeleste/vue-mc-gw";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { ProductCollection } from "@planetadeleste/vue-mc-shopaholic";
import { AppModule } from "@/store/app";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import OfferRow from "@/modules/pricelists/components/OfferRow.vue";
import PaginateMixin from "@/mixins/PaginateMixin";
import { LoadingModule } from "@/store/loading";

@Component({
  components: { OfferRow },
})
export default class PriceListTable extends Mixins(
  DataTableMixin,
  PaginateMixin
) {
  @Prop({ type: Object, required: true }) readonly priceList!: PriceList;
  obProductCollection: ProductCollection = new ProductCollection();
  sMethod = "getProducts";

  get company() {
    return AppModule.company;
  }

  get items() {
    return this.obProductCollection.getModelList();
  }

  get loading() {
    return LoadingModule.getLoading;
  }

  async getProducts() {
    LoadingModule.loading();

    const obCollection = new ProductCollection();
    obCollection
      .filterBy({ offerByPriceList: this.priceList.id, byNotGenerated: 1 })
      .page(this.currentPage);
    const response = await obCollection.fetch();

    if (response) {
      const obData = response.getData();
      this.mapPagination(obData);
      this.obProductCollection.clear();
      this.obProductCollection.add(obData.data);
    }

    LoadingModule.loaded();
  }

  mounted() {
    this.$nextTick(() => {
      const sPriceTitle = this.priceList.price_with_tax ? "with" : "without";
      const arHeaders: DataTableHeader[] = [
        { text: "name", value: "name", sortable: false },
        { text: "date.valid.from", value: "price_valid_at", sortable: false },
        { text: "currency", value: "currency", sortable: false },
        { text: `price.${sPriceTitle}.tax`, value: "price", sortable: false },
      ];
      this.setDTHeaders(arHeaders).mapDTHeaders().addDTActionsHeader();
      this.getProducts();
    });
  }
}
</script>
